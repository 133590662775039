import type BigNumber from 'bignumber.js';
import { get } from 'svelte/store';
import type { IToken } from './Tokens';
import { ChainId } from './Wallet';
import type { IChain } from './Chains';
import { IQuoteResult } from './QuoteResult';
import { isSupportedLanguage, Locale, TradeStatuses } from './Locale';
import { writable } from 'svelte/store';

export const LastScannedTimestamp = writable<number>(0);

export const SKIPSHIFT_ARBITRATOR: string = '0xE731EE06d80b665A23C49ECD94a60956a4EbBC15';

export interface ISkipShiftContracts {
    [keys: number]: string;
}

export interface ISkipShiftStartBlocks {
    [keys: number]: number;
}

export interface IRampPhases {
    [keys: number]: string;
}

export interface IP2PTier {
    [keys: number]: string;
}

export interface ISubGraphs {
    [keys: number]: string;
}

export interface IPaymentMethod extends IChain {
  specialVenueIDName?: string, 
  idInputFields?: string[]
};

export interface ApiEndpoint {
    url: string;
    key: string;
    params: {
        [keys: number]: string;
    };
}

export interface ICollatPreFetch {
    allowance: string;
    balance: string;
    minimumCollateral: string;
    nativeForCollateral: BigNumber;
    token: IToken;
    quote: IQuoteResult | undefined; // Replace 'any' with the actual type of 'quote'
    symbol: string;
}

export const skipId = () => SkipShiftContracts[get(ChainId) || 1].slice(0, 3).toUpperCase();

export const TakerTodo: IRampPhases = {
    0: '<i class="fad fa-traffic-light-stop text-red-700 px-1 text-lg"></i> Waiting for trade acceptance by counterparty...',
    1: '<i class="fad fa-traffic-light text-gray-500 px-1 text-lg"></i> Trade rejected, time to try again!',
    2: '<i class="fad fa-traffic-light-go text-green-600 px-1 text-lg"></i> It\'s time to send payment! Please send the correct amount and submit the confirmation #',
    3: '<i class="fad fa-traffic-light-stop text-red-700 px-1 text-lg"></i> Waiting for confirmation from counterparty...',
    4: '<i class="fad fa-traffic-light text-blue-500 px-1 text-lg"></i> Trade complete! All funds settled.',
    5: '<i class="fad fa-traffic-light-stop text-gray-500 px-1 text-lg"></i> Trade cancelled. Ready when you are!',
    6: '<i class="fad fa-traffic-light-slow text-yellow-400 px-1 text-lg"></i> Counterparty has requested to cancel this trade. Confirm or send them a message',
    7: '<i class="fad fa-traffic-light-stop text-red-700 px-1 text-lg"></i> You have requested to cancel this trade. Standby for confirmation.',
    8: '<i class="fad fa-traffic-light-stop text-red-700 px-1 text-lg"></i> Counterparty has requested arbitration, please keep an eye on the arbitration chat.',
    9: '<i class="fad fa-traffic-light-stop text-red-700 px-1 text-lg"></i> You have requested arbitration, please keep an eye on the arbitration chat.',
    10: '<i class="fad fa-traffic-light-stop text-red-700 px-1 text-lg"></i> Arbitration has settled in favor of the counterparty. All escrowed funds have settled.',
    11: '<i class="fad fa-traffic-light-stop text-red-700 px-1 text-lg"></i> Arbitration has settled in your favor. All escrowed funds have settled.',
    12: '<i class="fad fa-traffic-light-stop text-red-700 px-1 text-lg"></i> Arbitration has settled in favor of both parties. All escrowed funds have settled.',
};

export const MakerTodo: IRampPhases = {
    0: '<i class="fad fa-traffic-light-go text-green-600 px-1 text-lg"></i> Accept or reject this trade request',
    1: '<i class="fad fa-traffic-light text-gray-500 px-1 text-lg"></i> Trade rejected. No further action required.',
    2: '<i class="fad fa-traffic-light-stop text-red-700 px-1 text-lg"></i> Waiting for payment from counterparty...',
    3: '<i class="fad fa-traffic-light-go text-green-600 px-1 text-lg"></i> Please confirm payment receipt.',
    4: '<i class="fad fa-traffic-light text-blue-500 px-1 text-lg"></i> Trade complete! All funds settled.',
    5: '<i class="fad fa-traffic-light-stop text-gray-500 px-1 text-lg"></i> Trade cancelled. No further action required.',
    6: '<i class="fad fa-traffic-light-stop text-red-700 px-1 text-lg"></i> You have requested to cancel this trade. Standby for confirmation.',
    7: '<i class="fad fa-traffic-light-slow text-yellow-400 px-1 text-lg"></i> Counterparty has requested to cancel this trade. Confirm or send them a message',
    8: '<i class="fad fa-traffic-light-stop text-red-700 px-1 text-lg"></i> You have requested arbitration, please keep an eye on the arbitration chat.',
    9: '<i class="fad fa-traffic-light-stop text-red-700 px-1 text-lg"></i> Counterparty has requested arbitration, please keep an eye on the arbitration chat.',
    10: '<i class="fad fa-traffic-light-stop text-red-700 px-1 text-lg"></i> Arbitration has settled in your favor. All escrowed funds have settled.',
    11: '<i class="fad fa-traffic-light-stop text-red-700 px-1 text-lg"></i> Arbitration has settled in favor of the counterparty. All escrowed funds have settled.',
    12: '<i class="fad fa-traffic-light-stop text-red-700 px-1 text-lg"></i> Arbitration has settled in favor of both parties. All escrowed funds have settled.',
};

export const RampPhases: IRampPhases = {
    0: 'INITIATED',
    1: 'REJECTED',
    2: 'AWT_PAYMENT',
    3: 'CONF_SENT',
    4: 'COMPLETED',
    5: 'CANCELLED',
    6: 'CANCEL_REQ_MAKER',
    7: 'CANCEL_REQ_TAKER',
    8: 'ARBITRATION_REQ_MAKER',
    9: 'ARBITRATION_REQ_TAKER',
    10: 'SETTLED_MAKER',
    11: 'SETTLED_TAKER',
    12: 'SETTLED_SPLIT',
};

//old, do not use
export const PhaseToStatus: { [key: string]: string } = {
    INITIATED: 'Acceptance Pending',
    REJECTED: 'Rejected',
    AWT_PAYMENT: 'Awaiting Payment',
    CONF_SENT: 'Payment Conf. Sent',
    COMPLETED: 'Completed',
    CANCELLED: 'Cancelled',
    CANCEL_REQ_MAKER: 'Cancel Req: Maker',
    CANCEL_REQ_TAKER: 'Cancel Req: Taker',
    ARBITRATION_REQ_MAKER: 'Arbitration: Maker',
    ARBITRATION_REQ_TAKER: 'Arbitration: Taker',
    SETTLED_MAKER: 'Settled: Maker',
    SETTLED_TAKER: 'Settled: Taker',
    SETTLED_SPLIT: 'Settled: Split',
};

export function getPhaseToStatus(phase: number): string {
    let locale = get(Locale);
    // console.log('getting trade statuses', locale);

    const statusMap: { [key: number]: string } = {
        0: 'acceptancePending',
        1: 'rejected',
        2: 'awaitingPayment',
        3: 'paymentConfirmationSent',
        4: 'completed',
        5: 'cancelled',
        6: 'cancelReqMaker',
        7: 'cancelReqTaker',
        8: 'arbitrationMaker',
        9: 'arbitrationTaker',
        10: 'settledMaker',
        11: 'settledTaker',
        12: 'settledSplit',
    };

    const statusKey = statusMap[phase];

    return TradeStatuses[locale][statusKey as keyof (typeof TradeStatuses)['en']]; //en as representative locale
}

export const P2PTiers: IP2PTier = {
    0: 'LIMITED',
    1: 'PROFESSIONAL',
};

export const SkipShiftStartBlocks: ISkipShiftStartBlocks = {
    1: 17629826,
    5: 9295661,
    56: 29703919,
    369: 18286604,
    43114: 32219126,
    109: 1535465,
};

export const SkipShiftContracts: ISkipShiftContracts = {
    5: '0xB80d92461c137D8B4E1C73244c2ea3d60F893197',
    56: '0xB80d92461c137D8B4E1C73244c2ea3d60F893197',
    1: '0xB80d92461c137D8B4E1C73244c2ea3d60F893197',
    43114: '0xB80d92461c137D8B4E1C73244c2ea3d60F893197',
    369: '0xB80d92461c137D8B4E1C73244c2ea3d60F893197',
    109: '0x4568EEeB4dCFaAb68CAE4c88E54C0f6c26CFCc73',
};

export const SubGraphs: ISubGraphs = {
    56: 'https://api.thegraph.com/subgraphs/name/sycore0/skipshift-bsc',
    43114: 'https://api.thegraph.com/subgraphs/name/sycore0/skipshiftavax',
    1: 'https://api.thegraph.com/subgraphs/name/sycore0/skipshifteth',
    5: 'https://api.thegraph.com/subgraphs/name/sycore0/skipshift-goerli',
};

export const apiEndpoints = {
    1: {
        url: 'https://api.etherscan.io/api',
        key: '',
        params: {
            module: 'account',
            action: 'txlist',
            address: '',
            startblock: SkipShiftStartBlocks[1],
            endblock: 'latest',
            sort: 'desc',
            apikey: '',
        },
    },
    5: 'https://api-goerli.etherscan.io/api',
    56: 'https://api.skipshift.io',
    43114: 'https://api.skipshift.io',
    369: `https://scan.pulsechain.com/api`,

    137: 'https://api.skipshift.io',
    250: 'https://api.skipshift.io',
};

export interface IEventAsset {
    listingId: string;
    maker: string;
    status: string;
    asset: string;
    blockTimestamp: string;
}

export interface IRawListing {
    id: number;
    active: boolean;
    reqCollateral: boolean;
    type: '0' | '1';
    assetType: '0' | '1' | '2';
    tier: '0' | '1' | '2' | '3';
    spread: string;
    currency: any;
    asset: IToken;
    min: number | string;
    max: number | string;
    maker: string;
    taker?: string;
    venues: IP2PVenue[];
    chainID?: number;
    undercollateralized?: boolean;
}

export interface IP2PTrade {
    rampPhase?: number;
    type: 'OFFRAMP' | 'ONRAMP';
    listingId?: number | string;
    amount?: string;
    totalCost?: number | string;
    asset: IToken;
    currency?: IToken;
    taker?: string;
    maker?: string;
    destination?: string;
    takerDatahash?: string;
    venue?: IP2PVenue;
    referrer?: string;
    tradeId?: number;
    confId?: string;
    kypInfo?: string;
    kypFields?: string;
    autoCollateralize?: boolean;
    unreadMessages?: number;
    updateChat?: boolean;
    lastAction?: number | string;
}

export interface IP2PLiquidity {
    id?: number;
    active: boolean;
    reqCollateral: boolean;
    type: 'OFFRAMP' | 'ONRAMP';
    assetType: 'NATIVE' | 'ERC20' | 'NFT';
    tier: 'LIMITED' | 'STANDARD' | 'PREFERRED' | 'PROFESSIONAL' | LiqTiers;
    spread: string;
    currency: IToken;
    asset: IToken;
    min: number | string;
    max: number | string;
    maker: string;
    taker?: string;
    venues: IP2PVenue[];
    kypString: string;
}

export interface IP2PLiquidityLite {
    id: number;
    maker: string;
}

// for use with contract calls
export interface IRampDetails {
    active: boolean;
    reqCollateral: boolean;
    buyOrSell: RampType | string;
    assetType: AssetType | string;
    tier: LiqTiers | string;
    spread: string;
    currency: string;
    listingId: string;
    min: string;
    max: string;
    maker: string;
    taker: string;
    [keys: number]: string;
}

export interface IProviderAssets {
    0: IRampDetails[];
    // 1: {
    //   platform: string;
    //   identHash: string;
    //   [keys: number]: string;
    // }[],
    1: IP2PVenue[][];
    2: string[];
}

export enum RampType {
    OFFRAMP,
    ONRAMP,
}

export enum LiqTiers {
    LIMITED,
    PROFESSIONAL,
}

export const enum AssetType {
    NATIVE,
    ERC20,
    NFT721,
    NFT1155,
}

export const enum LiqDirection {
    OFFRAMP, // provider is buying crypto from the user with fiat
    ONRAMP, // provider is selling crypto to the user for fiat
}

// combines p2p trades and p2p liquidity into one interface
export interface IP2PTradeLiq extends IP2PTrade {
    id?: number;
    spread?: string;
    active: boolean;
    reqCollateral: boolean;
    assetType: 'NATIVE' | 'ERC20' | 'NFT' | AssetType | string;
    tier: 'LIMITED' | 'STANDARD' | 'PREFERRED' | 'PROFESSIONAL' | LiqTiers | string;
    min: number | string;
    max: number | string;
    venues: IP2PVenue[];
    lastAction: number | string;
    confId?: string;
    rampPhase?: number;
    status?: number;
    kypInfo?: string;
    kypFields?: string;
    undercollateralized?: boolean;
}

export interface IPayLoad {
    skipShift_tx_id: string | number; // tradeId
    venue: string; // venue.platform
    maker_venue_id: string; // venue.makerId
    taker_venue_id: string; // venue.takerId
    payment_conf_id: string; // payment confirmation number/id from venue
    chain_id: number; // chainId
    kyp_info: string;
}

export interface IPayLoads {
    [key: string]: IPayLoad; // key is skipShift_tx_id
}

export interface ILiqPair {
    token0: string;
    token1: string;
}

export interface IProviderRate {
    spread: number | string;
    totalAmount: BigNumber;
    maker: string;
    venue: IP2PVenue[] | string[];
    listingId?: number | string;
    min: BigNumber;
    max: BigNumber;
    reqCollateral?: boolean;
    kypString: string;
    providerHours?: string;
}

export interface IP2PVenue {
    platform: string;
    identHash: string;
    userId?: string;
    makerId?: string;
    takerId?: string;
}

export interface IUserInfo {
    tradeLocked: string;
    score: string;
    firstTrade: string;
    lastTrade: string;
    collateral: string;
    tradeIndex: string;
    collatBal?: string;
    [propName: number]: any;
}

export const BlacklistedProviders: string[] = [
  '0xb89561c29Eaa8Ab8c58A22cE7e3DaAdeCD9A5C97',
  '0x9791C9fE21CF7Ed9324d9Ed7d09403388C3889B9',
  '0x0e90C605432326c88F642c21daC2868a083660F2'
]

export const currencies: IToken[] = [
    {
        name: 'United States Dollar',
        symbol: 'USD',
        logoURI: '/currencies/usd.svg',
    },
    {
        name: 'Canadian Dollar',
        symbol: 'CAD',
        logoURI: '/currencies/cad.png',
    },
    {
        name: 'British Pound Sterling',
        symbol: 'GBP',
        logoURI: '/currencies/pound.svg',
    },
    {
        name: 'Euro',
        symbol: 'EUR',
        logoURI: '/currencies/euro.svg',
    },
    {
        name: 'Japanese Yen',
        symbol: 'JPY',
        logoURI: '/currencies/yen.svg',
    },
    {
        name: 'Australian Dollar',
        symbol: 'AUD',
        logoURI: '/currencies/aud.png',
    },
    {
        name: 'Swiss Franc',
        symbol: 'CHF',
        logoURI: '/currencies/chf.png',
    },
    {
        name: 'Chinese Yuan',
        symbol: 'CNY',
        logoURI: '/currencies/cny.png',
    },
    {
        name: 'Hong Kong Dollar',
        symbol: 'HKD',
        logoURI: '/currencies/hkd.png',
    },
    {
        name: 'Mexican Peso',
        symbol: 'MXN',
        logoURI: '/currencies/mxn.svg',
    },
    {
        name: 'Indian Rupee',
        symbol: 'INR',
        logoURI: '/currencies/inr.svg',
    },
    {
        name: 'Pakistani Rupee',
        symbol: 'PKR',
        logoURI: '/currencies/pkr.svg',
    },
    {
        name: 'Bangladeshi Taka',
        symbol: 'BDT',
        logoURI: '/currencies/bdt.svg',
    },
    {
        name: 'Argentine Peso',
        symbol: 'ARS',
        logoURI: '/currencies/ars.svg',
    },
    {
        name: 'Venezuelan Bolivar',
        symbol: 'VES',
        logoURI: '/currencies/ves.svg',
    },
    {
        name: 'Chilean Peso',
        symbol: 'CLP',
        logoURI: '/currencies/clp.svg',
    },
    {
        name: 'Colombian Peso',
        symbol: 'COP',
        logoURI: '/currencies/cop.svg',
    },
    {
        name: 'Nicaraguan Córdoba',
        symbol: 'NIO',
        logoURI: '/currencies/nio.svg',
    },
    {
        name: 'Panamanian Balboa',
        symbol: 'PAB',
        logoURI: '/currencies/pab.svg',
    },
    {
      name: 'Bolivian Boliviano',
      symbol: 'BOB',
      logoURI: '/currencies/bob.svg'
    }
];

//USE CHAINID FOR KEY
export const paymentMethods: IPaymentMethod[] = [
  {
    chainId: 1,
    shortName: 'Cash App',
    name: 'cash-app-square-cash',
    logoURI: '/payments/cash-app.png',
    stableCoins: [],
    idInputFields: []
  },
  {
    chainId: 2,
    shortName: 'Chime',
    name: 'chime-instant-transfer',
    logoURI: '/payments/chime-instant-transfer.png',
    stableCoins: [],
    idInputFields: []
  },
  {
    chainId: 3,
    shortName: 'PayPal',
    name: 'paypal',
    logoURI: '/payments/paypal.png',
    stableCoins: [],
    idInputFields: [],
  },
  {
    chainId: 4,
    shortName: 'Venmo',
    name: 'venmo',
    logoURI: '/payments/venmo.png',
    stableCoins: [],
    idInputFields: []
  },
  {
    chainId: 5,
    shortName: 'Western Union',
    name: 'western-union',
    logoURI: '/payments/western-union.png',
    stableCoins: [],
    idInputFields: []
  },
  {
    chainId: 6,
    shortName: 'Zelle',
    name: 'zelle',
    logoURI: '/payments/zelle.png',
    stableCoins: [],
    idInputFields: [],
  },
  {
    chainId: 7,
    shortName: 'MoneyGram',
    name: 'moneygram',
    logoURI: '/payments/moneygram.png',
    stableCoins: [],
    idInputFields: []
  },
  {
    chainId: 8,
    shortName: 'Revolut',
    name: 'revolut',
    logoURI: '/payments/revolut.png',
    stableCoins: [],
    idInputFields: []
  },
  {
    chainId: 9,
    shortName: 'Payoneer',
    name: 'payoneer',
    logoURI: '/payments/payoneer.webp',
    stableCoins: [],
    idInputFields: []
  },
  {
    chainId: 10,
    shortName: 'Yandex',
    name: 'yandex',
    logoURI: '/payments/yandex.png',
    stableCoins: [],
    idInputFields: []
  },
  {
    chainId: 11,
    shortName: 'SEPA',
    name: 'sepa',
    logoURI: '/payments/sepa.png',
    stableCoins: [],
    idInputFields: ['Name', 'IBAN']
  },
  {
    chainId: 12,
    shortName: 'Webmoney',
    name: 'webmoney',
    logoURI: '/payments/webmoney.png',
    stableCoins: [],
    idInputFields: []
  },
  {
    chainId: 13,
    shortName: 'Wise',
    name: 'transfer-wise',
    logoURI: '/payments/transferwise.png',
    stableCoins: [],
    idInputFields: []
  },
  {
    chainId: 14,
    shortName: 'ACH',
    name: 'ach',
    logoURI: '/payments/ach.png',
    stableCoins: [],
    idInputFields: []
  },
  {
    chainId: 15,
    shortName: 'Bank Wire',
    name: 'bank-wire',
    logoURI: '/payments/bankwire.webp',
    stableCoins: [],
    idInputFields: []
  },
  {
    chainId: 16,
    shortName: 'WeChat',
    name: 'wechat-pay',
    logoURI: '/payments/wechat.png',
    stableCoins: [],
    idInputFields: []
  },
  {
    chainId: 17,
    shortName: 'AliPay',
    name: 'alipay',
    logoURI: '/payments/alipay.png',
    stableCoins: [],
    idInputFields: []
  },
  {
    chainId: 18,
    shortName: 'MercadoPago',
    name: 'mercadopago',
    logoURI: '/payments/mercadopago.png',
    stableCoins: [],
    specialVenueIDName: 'CLABE',
    idInputFields: []
  },
  {
    chainId: 19,
    shortName: 'Albo',
    name: 'alipay',
    logoURI: '/payments/albo.png',
    stableCoins: [],
    specialVenueIDName: 'CLABE',
    idInputFields: []
  },
  {
    chainId: 20,
    shortName: 'Fondeadora',
    name: 'alipay',
    logoURI: '/payments/fondeadora.png',
    stableCoins: [],
    specialVenueIDName: 'CLABE',
    idInputFields: []
  },
  {
    chainId: 21,
    shortName: 'Bizum',
    name: 'bizum',
    logoURI: '/payments/bizum.png',
    stableCoins: [],
    idInputFields: []
  },
  {
    chainId: 22,
    shortName: 'bKash',
    name: 'bkash',
    logoURI: '/payments/bkash.png',
    stableCoins: [],
    idInputFields: []
  },
  {
    chainId: 23,
    shortName: 'RocketPay',
    name: 'rocketpay',
    logoURI: '/payments/rocketpay.png',
    stableCoins: [],
    idInputFields: []
  },
  {
    chainId: 24,
    shortName: 'Nagad',
    name: 'nagad',
    logoURI: '/payments/nagad.png',
    stableCoins: [],
    idInputFields: []
  },
  {
    chainId: 25,
    shortName: 'BDV',
    name: 'bdv',
    logoURI: '/payments/bdv.png',
    stableCoins: [],
    idInputFields: ['DNI', 'Banco #', 'Teléfono'],
  },
  {
    chainId: 26,
    shortName: 'Zinli',
    name: 'zinli',
    logoURI: '/payments/zinli.png',
    stableCoins: [],
    idInputFields: ["Nombre", "Correo"]
  },
  {
    chainId: 27,
    shortName: 'Uala',
    name: 'uala',
    logoURI: '/payments/uala.png',
    stableCoins: [],
    idInputFields: ['CBU/CVU', 'Alias', 'DNI/CUIL']
  },
  {
    chainId: 28,
    shortName: 'Brubank',
    name: 'brubank',
    logoURI: '/payments/brubank.png',
    stableCoins: [],
    idInputFields: ['CBU/CVU', 'Alias', 'DNI/CUIL']
  },
  {
    chainId: 29,
    shortName: 'NaranjaX',
    name: 'naranjax',
    logoURI: '/payments/naranjax.png',
    stableCoins: [],
    idInputFields: ['CBU/CVU', 'Alias', 'DNI/CUIL']
  },
  {
    chainId: 30,
    shortName: 'Prex',
    name: 'prex',
    logoURI: '/payments/prex.png',
    stableCoins: [],
    idInputFields: ['CBU/CVU', 'Alias', 'DNI/CUIL']
  },
  {
    chainId: 31,
    shortName: 'Airtm',
    name: 'airtm',
    logoURI: '/payments/airtm.png',
    stableCoins: [],
    idInputFields: [],
    specialVenueIDName: 'Email'
  },
  {
    chainId: 32,
    shortName: 'Tap',
    name: 'tap',
    logoURI: '/payments/tap.png',
    stableCoins: [],
    idInputFields: [],
  },
  {
    chainId: 33,
    shortName: 'Paytm',
    name: 'paytm',
    logoURI: '/payments/paytm.png',
    stableCoins: [],
    idInputFields: [],
  },
  {
    chainId: 34,
    shortName: 'Mercantil',
    name: 'mercantil',
    logoURI: '/payments/mercantil.png',
    stableCoins: [],
    idInputFields: ['Nombre y Apellido', 'Cedula', 'Banco #', 'Telefono/Telegram'],
  },
  {
    chainId: 35,
    shortName: 'BNC',
    name: 'bnc',
    logoURI: '/payments/bnc.png',
    stableCoins: [],
    idInputFields: ['Nombre y Apellido', 'Cedula', 'Banco #', 'Telefono/Telegram'],
  },
  {
    chainId: 36,
    shortName: 'Banesco',
    name: 'banesco',
    logoURI: '/payments/banesco.png',
    stableCoins: [],
    idInputFields: ['Nombre y Apellido', 'Cedula', 'Banco #', 'Telefono/Telegram'],
  },
  {
    chainId: 37,
    shortName: 'BBVAProvincial',
    name: 'bbvaprovincial',
    logoURI: '/payments/bbvaprovincial.png',
    stableCoins: [],
    idInputFields: ['Nombre y Apellido', 'Cedula', 'Banco #', 'Telefono/Telegram'],
  },
  {
    chainId: 38,
    shortName: 'AppleCash',
    name: 'applecash',
    logoURI: '/payments/applecash.png',
    stableCoins: [],
    idInputFields: [],
  },
  {
    chainId: 39,
    shortName: 'YourSafe',
    name: 'yoursafe',
    logoURI: '/payments/yoursafe.png',
    stableCoins: [],
    idInputFields: [],
  },
  {
    chainId: 40,
    shortName: 'Banpro',
    name: 'banpro',
    logoURI: '/payments/banpro.png',
    stableCoins: [],
    idInputFields: ["Nombre", "Telefono"],
  },
  {
    chainId: 41,
    shortName: 'Bancolombia',
    name: 'bancolombia',
    logoURI: '/payments/bancolombia.png',
    stableCoins: [],
    idInputFields: ["Nombre y Apellido", "Documento de Identidad", "Tipo de cuenta", "Número de cuenta"],
  },
  {
    chainId: 42,
    shortName: 'Nequi',
    name: 'nequi',
    logoURI: '/payments/nequi.png',
    stableCoins: [],
    idInputFields: ["Nombre y Apellido", "Telefono"],
  },
  {
    chainId: 42,
    shortName: 'Mony',
    name: 'mony',
    logoURI: '/payments/mony.png',
    stableCoins: [],
    idInputFields: ["Nombre", "Correo"],
  },
  {
    chainId: 43,
    shortName: 'UPI',
    name: 'upi',
    logoURI: '/payments/upi.png',
    stableCoins: [],
    idInputFields: [],
  },
  {
    chainId: 44,
    shortName: 'Wally',
    name: 'wally',
    logoURI: '/payments/wally.png',
    stableCoins: [],
    idInputFields: [],
  },
  {
    chainId: 45,
    shortName: 'Meru',
    name: 'meru',
    logoURI: '/payments/meru.png',
    stableCoins: [],
    idInputFields: ["Nombre", "Email"],
  },
  {
    chainId: 46,
    shortName: 'Banamex',
    name: 'banamex',
    logoURI: '/payments/banamex.png',
    stableCoins: [],
    idInputFields: ["CLABE", "Nombre"],
  },
  {
    chainId: 47,
    shortName: 'Coppel',
    name: 'coppel',
    logoURI: '/payments/coppel.png',
    stableCoins: [],
    idInputFields: [],
  },
  {
    chainId: 48,
    shortName: 'Elektra',
    name: 'elektra',
    logoURI: '/payments/elektra.png',
    stableCoins: [],
    idInputFields: [],
  },
  {
    chainId: 49,
    shortName: 'STP',
    name: 'stp',
    logoURI: '/payments/stp.png',
    stableCoins: [],
    idInputFields: ["Nombre", "Email"],
  },
  {
    chainId: 50,
    shortName: 'Santander',
    name: 'santander',
    logoURI: '/payments/santander.png',
    stableCoins: [],
    idInputFields: ["Nombre", "Email"],
  },
  {
    chainId: 51,
    shortName: 'Banorte',
    name: 'banorte',
    logoURI: '/payments/banorte.png',
    stableCoins: [],
    idInputFields: [],
  },
  {
    chainId: 52,
    shortName: 'OXXO',
    name: 'oxxo',
    logoURI: '/payments/oxxo.png',
    stableCoins: [],
    idInputFields: [],
  },
  {
    chainId: 54,
    shortName: '7Eleven',
    name: '7eleven',
    logoURI: '/payments/7eleven.png',
    stableCoins: [],
    idInputFields: [],
  },
];

export const paymentMethodsMap: { [key: string]: IChain & { specialVenueIDName?: string, idInputFields: string[] } } = Object.values(paymentMethods).reduce((acc, method) => ({ ...acc, [method.shortName || '']: method }), {});

export const chains: IChain[] = [
    {
        chainId: 1,
        shortName: 'Ethereum',
        name: 'ETH',
        logoURI: 'https://cryptologos.cc/logos/ethereum-eth-logo.svg',
        stableCoins: [],
    },
    {
        chainId: 5,
        shortName: 'Goërli',
        name: 'gETH',
        logoURI: '/images/chains/goerli.png',
        stableCoins: [],
    },
    {
        chainId: 56,
        shortName: 'Binance Coin',
        name: 'BNB',
        logoURI: 'https://cryptologos.cc/logos/binance-coin-bnb-logo.svg',
        stableCoins: [],
    },
    {
        chainId: 43114,
        shortName: 'Avalanche',
        name: 'BNB',
        logoURI: 'https://cryptologos.cc/logos/avalanche-avax-logo.svg',
        stableCoins: [],
    },
    {
        chainId: 369,
        shortName: 'Pulse',
        name: 'PLS',
        logoURI: 'https://cryptologos.cc/logos/avalanche-avax-logo.svg',
        stableCoins: [],
    },
    // {
    //   chainId: 250,
    //   shortName: 'Fantom',
    //   name: 'FTM',
    //   logoURI: 'https://cryptologos.cc/logos/fantom-ftm-logo.svg',
    // },
    // {
    //   chainId: 137,
    //   shortName: 'Polygon',
    //   name: 'POLY',
    //   logoURI: 'https://cryptologos.cc/logos/polygon-matic-logo.svg',
    // },
];

export const tokens: IToken[] = [
    {
        chainId: 1,
        name: 'Ethereum',
        symbol: 'ETH',
        address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        logoURI: '/images/coins/ETH.svg',
        decimals: 18,
    },
    {
        chainId: 1,
        name: 'DAI',
        symbol: 'DAI',
        logoURI: 'https://cryptologos.cc/logos/multi-collateral-dai-dai-logo.svg',
        address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
        decimals: 18,
    },
    {
        chainId: 1,
        name: 'USDC',
        symbol: 'USDC',
        address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        logoURI: 'https://cryptologos.cc/logos/usd-coin-usdc-logo.svg',
        decimals: 6,
    },
    {
        chainId: 1,
        name: 'USDT',
        symbol: 'USDT',
        address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
        logoURI: 'https://cryptologos.cc/logos/tether-usdt-logo.svg',
        decimals: 6,
    },
    {
        chainId: 56,
        name: 'Binance Coin',
        symbol: 'BNB',
        address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        logoURI: 'https://cryptologos.cc/logos/binance-coin-bnb-logo.svg',
        decimals: 18,
    },
    {
        chainId: 56,
        name: 'DAI',
        symbol: 'DAI',
        address: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
        logoURI: 'https://cryptologos.cc/logos/multi-collateral-dai-dai-logo.svg',
        decimals: 18,
    },
    {
        chainId: 56,
        name: 'USDC',
        symbol: 'USDC',
        address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        logoURI: 'https://cryptologos.cc/logos/usd-coin-usdc-logo.svg',
        decimals: 18,
    },
    {
        chainId: 56,
        name: 'USDT',
        symbol: 'USDT',
        address: '0x55d398326f99059ff775485246999027b3197955',
        logoURI: 'https://cryptologos.cc/logos/tether-usdt-logo.svg',
        decimals: 18,
    },
    {
        chainId: 43114,
        name: 'Avalanche',
        symbol: 'AVAX',
        address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        logoURI: 'https://cryptologos.cc/logos/avalanche-avax-logo.svg',
        decimals: 18,
    },
    {
        chainId: 43114,
        name: 'USD Coin',
        symbol: 'USDC',
        address: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
        logoURI: '/images/coins/USDC.svg',
        decimals: 6,
    },
    {
        chainId: 43114,
        name: 'USD Coin',
        symbol: 'USDC.e',
        address: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
        logoURI: '/images/coins/usdce.webp',
        decimals: 6,
    },
    {
        chainId: 369,
        name: 'Pulse',
        symbol: 'PLS',
        address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        logoURI: '/images/coins/PLS.svg',
        decimals: 18,
    },
    {
        chainId: 109,
        name: 'Shibarium',
        symbol: 'BONE',
        address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        logoURI: '/images/coins/BONE.svg',
        decimals: 18,
    },
];

export const venueFormats = {
    'Venmo': '@venmo',
    'Cash App': '$cashapp',
    'Chime': '$ChimeSign',
    'Zelle': 'you@email.com',
};
