<script lang="ts">
    import { afterUpdate, onMount } from 'svelte';
    import type { IP2PTradeLiq, IRawListing } from '../../Data/P2P';
    import { paymentMethods } from '../../Data/P2P';
    import BigNumber from 'bignumber.js';
    import { WalletConnected, ChainId, Wallet } from '../../Data/Wallet';
    import { createEventDispatcher } from 'svelte';
    import PaymentMethodButton from './PaymentMethodButton.svelte';
    import { generateCoinSVG } from '../../API/utils/tokenImagerator';
    import { SkipTexts, Locale } from '../../Data/Locale';
    import subZero from '../../API/helpers/subZero';
    import autoAnimate from '../../API/helpers/autoAnimate';
    import { addActionLogLine } from '../../Data/ActionLogs';
    import { changeLiqStatus } from '../../API/P2P';
    import chains from '../../Data/Chains';
    import { hexToRgba } from '../../API/helpers/hexToRGBA';
    import chainSwitcher from '../../API/helpers/chainSwitcher';
    import { supportedChains } from '../../Data/RBXToken';
    import { CurrencyRates } from '../../Data/CurrencyRates';
    import ChainList from '../../components/Common/ChainList.svelte';
    import { fetchAddressListings } from '../../API/helpers/fetchAddressListings';
    import { SPREAD_LIMIT } from '../../Data/Constants';
    import { USER_SETTINGS } from '../../Data/TransactionSettings';
    
    export let liqList: IP2PTradeLiq[];
    export let loading: boolean = false;
    // export let buttonGradient: string;
    export let switchValueInactive = false;

    const availableChainIDs = [1, 56, 43114, 109, 369];
    let chainsToShow = availableChainIDs.slice(0, 3);
    let showingAllChains = false;
    // let listing: IP2PTradeLiq;

    $: filteredLiqList = liqList
        .filter(listing => switchValueInactive ? !listing.active : listing.active)
        .sort((a, b) => {
            if (a.asset.chainId === $ChainId && b.asset.chainId !== $ChainId) {
                return -1;
            } else if (a.asset.chainId !== $ChainId && b.asset.chainId === $ChainId) {
                return 1;
            } else {
                return 0;
            }
        });

    let updateTimeout: any;
    let updateInterval = 45000;
    let collateralText = '<i class="fad fa-lock text-green-400 fa-stack-1x"></i>';
    let showChainList = false;

    const dispatch = createEventDispatcher();

    const onDisableListing = async (listing: IP2PTradeLiq) => {
        if($ChainId !== listing.asset.chainId){
            await chainSwitcher(listing.asset.chainId || 0);
        }

        //check again in case user needed to switch chains and rejected mm prompt
        if($ChainId == listing.asset.chainId){
            await changeLiqStatus(listing, false);
        }else{
            addActionLogLine(`Please connect to ${chains[listing.asset.chainId || 0].shortName} to disable this listing.`)
        }
    };

    const onReactivateListing = async (listing: IP2PTradeLiq) => {
        if($ChainId !== listing.asset.chainId){
            await chainSwitcher(listing.asset.chainId || 0);
        }

        if($ChainId == listing.asset.chainId){
            await changeLiqStatus(listing, true);
        }else{
            addActionLogLine(`Please connect to ${chains[listing.asset.chainId || 0].shortName} to reactivate this listing.`)
        }
    }

    const onChainSelectClick = async (chainId: number) => {
        loading = true;
        liqList = [];
        try{
            await chainSwitcher(chainId);
            dispatch('chainSwitch');
        }catch(e){
            console.log(e);
            loading = false;
        }
        console.log('chain switch dispatched');
    }

    // //dedupe
    // $: liqList = Array.from(new Set(liqList.map(listing => JSON.stringify(listing))))
    //         .map(str => JSON.parse(str));

    const copyToClipboard = async (text: string) => {
        await navigator.clipboard.writeText(text);
        addActionLogLine(`Copied to clipboard!`);
    };
    const getVenueIcon = (listing: IP2PTradeLiq, index?: number) => {
        const venues = listing.venues as unknown as [string, string][]
        let match = Object.values(paymentMethods).find((method) => method.shortName === venues[index || 0][0]);
        if (match) return match.logoURI;
        else return generateCoinSVG(listing.asset.symbol || '');
    };

    //only use toFixed(3) if there are decimals to display (saves space in UI)
    const formatValue = (value: string, decimals: number) => BigNumber(value || 0).div(10 ** (decimals || 18)).toFixed(BigNumber(value).mod(10 ** (decimals || 18)).isZero() ? 0 : 3);

    afterUpdate(async () => {
        if($ChainId != 0 && !chainsToShow.includes($ChainId)){
            chainsToShow.push($ChainId);
        }
        chainsToShow = [...chainsToShow];

        if($WalletConnected && liqList.length == 0){
            const skipscanListings = await fetchAddressListings($Wallet.address) || [];
            // console.log('fetched listings', skipscanListings);
            if(skipscanListings?.length > 0){
                liqList = [...skipscanListings];
                showingAllChains = true;
            }
        }
        // console.log('supportedChains', chainsToShow)
    })
</script>

{#if showChainList}
    <div class="ml-4 mt-4">
        <ChainList
            on:chainSelected={(event) => onChainSelectClick(event.detail.chain.chainId)}
            on:close={() => showChainList = false}
        />
    </div>
{/if}
<div class="min-h-[12em] max-h-[40em] h-full overflow-auto flex flex-col items-center pt-4 pb-4 no-scrollbar" use:autoAnimate>
    {#if filteredLiqList.length == 0}
        {#if loading}
            <div class="pending-holder">
                <div class="spinner" />
                <span class="pt-4 loading-text">Loading Liquidity list...</span>
            </div>
        {:else}
            <div class="pending-holder">
                <i class="fad fa-exchange-alt text-9xl text-sky-200"></i>
                <span class="pt-4 mx-8 text-center text-gray-400" >{!$WalletConnected ? 'Connect Wallet' : SkipTexts[$Locale].no_listings_found}</span>
            </div>
        {/if}
    {/if}
    <div class:hidden={showingAllChains} class="flex items-center justify-center">
        {#each chainsToShow as chain}
            <button class="chain-button" on:click="{() => onChainSelectClick(chain)}">
                <img
                class={$ChainId === chain ? "selected text-white chain-button buttonGradient" : "chain-button"} 
                src="{chains[chain]?.logoURI}" 
                alt="{chains[chain]?.shortName}"
                />
            </button>
        {/each}
        {#if supportedChains.length > 3}
            <button on:click={() => showChainList = true} class="text-sky-500 text-3xl p-4 animate-bounce font-bold rounded-xl">+</button>
        {/if}
    </div>
    {#if $WalletConnected && filteredLiqList.length > 0}
        <div class="flex items-start justify-start w-full px-4 pb-1">
            <span class="text-xl infoText font-light">{switchValueInactive ? 'Disabled' : 'Active'} listings:</span>
            <div class="w-fit m-auto mr-0 pl-3 text-end switchbox-highlight">
                <input class="rounded-full mr-1" type="checkbox" id="showCompletedTrades" bind:checked={$USER_SETTINGS.showInactive} />
                {'Show inactive liq'}
            </div>
        </div>
    {/if}
    {#each filteredLiqList as listing}
        {#if (switchValueInactive ? !listing.active : listing.active)}
            <div class="liq-item flex flex-col mb-4">
                <div class="flex items-center justify-around w-full">
                    <div class="flex flex-col">
                        <div class="flex">
                            {#if listing.type == "OFFRAMP"}
                                <img src="/icons/arrow-down.svg" class="w-[20px] pr-2" alt="arrow down" />
                            {:else}
                                <img src="/icons/arrow-up.svg" class="w-[20px] pr-2" alt="arrow up" />
                            {/if}
                            <div class="pr-4 md:pr-8 flex flex-col">
                                <div class="font-medium infoText">
                                    {listing.asset?.symbol}
                                </div>
                                <div class="font-medium infoText">
                                    {listing.currency?.symbol || listing.currency}
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center justify-center pt-2">
                            <!-- <span class="pt-4 text-left text-sm">Chain:</span> -->
                            <span class="text-sky-500 pr-2 text-xs">{chains[listing.asset.chainId || 0].shortName}</span>
                            <img class="w-4 h-4" src="{chains[listing.asset.chainId || 0].logoURI}" alt=""/>
                        </div>
                    </div>

                    <!-- <img src="/icons/arrow-down.svg" class="width-[20px] pr-2" alt="arrow down" /> -->
                    <div class="flex flex-col">
                        {#if listing.spread && +listing.spread >= SPREAD_LIMIT}
                            <span class="infoText" data-tooltip="Trades will not be matched with this listing because it is over the spread limit. Please disable this listing and relist with a lower spread.">⚠️WARNING: Over the spread limit of {SPREAD_LIMIT / 100}%⚠️</span>
                        {:else if listing.undercollateralized}
                            <span class="infoText" data-tooltip="Trades will not be matched with this listing because it is undercollateralized. Please disable this listing and create a new one.">⚠️WARNING: Undercollateralized⚠️</span>
                        {:else}
                            <div class="font-normal text-right infoText">
                                {formatValue(String(listing.min), listing.asset.decimals || 18)}
                                <span class="font-semibold text-xs">{listing.asset?.symbol} min</span>
                            </div>
                            <div class="font-normal text-right infoText">
                                {formatValue(String(listing.max), listing.asset.decimals || 18)}
                                <span class="font-semibold text-xs">{listing.asset?.symbol} max</span>
                            </div>
                        {/if}

                        <div class="font-normal text-right infoText">
                            {BigNumber(listing.spread || 0).div(100)}%
                            <span class="font-semibold text-xs">{'SPREAD'}</span>
                        </div>
                    </div>
                    <div class="flex flex-col px-4">
                        {#if listing.active}
                            <button id='trash' class="pb-2" on:click={() => onDisableListing(listing)}>
                                <i class="fa-solid fa-trash text-red-600" />
                            </button>
                        {:else}
                            <button id='reactivate' data-tooltip="Reactivate this listing" class="pb-2 no-tooltip-border no-tooltip-cursor" on:click={() => onReactivateListing(listing)}>
                                <i class="fa-solid fa-play text-emerald-600" />
                            </button>
                        {/if}
                        {#if listing.reqCollateral}
                            <i data-tooltip="Requires Collateral" class="fa-solid fa-lock no-tooltip-border text-green-400"/>
                        {:else}
                            <span data-tooltip="Does not require collateral" class="fa-stack text-xs fa-2x">
                                {@html collateralText}
                                <i class="fad fa-ban fa-stack-2x" style="color:Tomato"></i>
                            </span>
                        {/if}
                    </div>
                    <div class="px-2 pt-1">
                        <span class="text-sm infoText">Venues:</span>
                        <div class="flex">
                            {#each listing.venues as venue, index}
                                {#if index < 2}
                                    <div class="flex flex-col items-center justify-center mx-1 pt-2">
                                        <img class="venue-icon" src={getVenueIcon(listing, index)} alt=""/>
                                        <span class="font-normal text-center infoText text-xs">{typeof venue === 'object' && 'platform' in venue ? venue.platform : venue[0]}</span>
                                    </div>
                                {/if}
                            {/each}
                            {#if listing.venues.length > 2}
                                <span class="text-sky-500 ml-2 font-bold mt-4 text-2xl">+{listing.venues.length - 2}</span>
                            {/if}
                        </div>
                    </div>
            </div>
                {#if (listing.currency?.symbol && listing.currency.symbol != 'USD')}
                    <span class="text-sky-500 pt-2 text-xs pl-[10px]">{`1 USD `} = {$CurrencyRates[listing.currency?.symbol].toFixed(3)} {listing.currency?.symbol}</span>
                {/if}
            </div>
        {/if}
    {/each}
</div>

<style lang="scss">
    .liq-item {
        width: 95%;
        padding: 16px;
        border-radius: 8px;
        background: var(--off-white, #f2f2f2);
        background: var(--input-bg);
    }
    .pending-holder {
        display: flex;
        flex-direction: column;
        height: 90%;
        align-items: center;
        justify-content: center;
    }
    .venue-icon {
        width: 1em;
    }
    .chain-button{
        width: 4em;
        border-radius: 16px;
        padding: 0.25rem 0.5rem;
    }
    .selected{
        background: var(--ble, linear-gradient(90deg, #0EA5E9 0%, rgba(14, 165, 233, 0.75) 100%));
    }
    .spinner {
        width: 4em;
        height: 4em;
        border: 0.5em solid rgba(0, 0, 0, 0.1);
        border-left-color: #0ea5e9;
        border-radius: 50%;
        animation: spin 1s linear infinite;
    }

    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
</style>