import { PAGE_SIZE } from './../Data/Constants';
import { skipId, SKIPSHIFT_ARBITRATOR } from './../Data/P2P';
import type { IP2PVenue, IPayLoad, IP2PTradeLiq, IP2PTrade } from './../Data/P2P';
import { Wallet, CryptKey, ChatStore, Xmtp, ChainId, WalletVerified } from '../Data/Wallet';
import { web } from '../Data/Web3Store';
import { get } from 'svelte/store';
import { Client, DecodedMessage, SortDirection } from '@xmtp/xmtp-js';
import type { Conversation, Signer } from '@xmtp/xmtp-js';
import { ethers } from 'ethers';
import { writable } from 'svelte/store';
import { IMessageTracker, MESSAGE_TRACKER, messageTrackerStore } from '../Data/MessageCounts';

export const XMTPLoadingProgress = writable<number>(0);
export const XMTPFragmentData = writable<{loaded: number, total: number}>({loaded: 0, total: 0})

export interface IEncryptedLoad {
    skipShift_tx_id: string;
    payment_info: string;
    venue: string;
    payment_conf_id: string;
    sender: string;
    maker_venue_id?: string;
    taker_venue_id?: string;
    kypString?: string;
}

export const initXmtp = async () => {
    const wallet = get(Wallet);
    console.log('initXmtp wallet: ', wallet);
    if (!wallet.address) return;
    const key = get(CryptKey);

    let xmtp = get(Xmtp);
    console.log('xmtp.address.toLowerCase(): ', xmtp?.address?.toLowerCase());

    if (xmtp?.address.toLowerCase() === wallet.address.toLowerCase()) return xmtp;

    try {
        const web3 = get(web);
        const provider = new ethers.providers.Web3Provider(web3.currentProvider as ethers.providers.ExternalProvider);
        const signer = provider.getSigner();

        const keys = key[0] > 0 ? key : await getKeys(signer);

        xmtp = await Client.create(signer, { env: 'production', privateKeyOverride: keys });
        Xmtp.set(xmtp);

        console.log('xmtp.address.toLowerCase(): ', xmtp.address.toLowerCase());
        console.log('wallet.address.toLowerCase(): ', wallet.address.toLowerCase());
        xmtp = get(Xmtp);
        if (xmtp.address.toLowerCase() !== wallet.address.toLowerCase()) {
            const reKey = await getKeys(signer);
            Xmtp.set(await Client.create(signer, { env: 'production', privateKeyOverride: reKey }));
            xmtp = get(Xmtp);
        }

        ChatStore.set(xmtp.conversations);

        // let _ = new Promise.all([
        //   new Promise((r) => setTimeout(r, 5000)),
        //   ChatStore.set(xmtp.conversations)]);

        // chatList = await chats.list();
        // msgStream = await chats.stream();

        return xmtp;
    } catch (e) {
        console.log('xmtp init error: ', e);
        return null;
    }
};

export const isWallet = async (): Promise<boolean> => {
    const wallet = get(Wallet);
    // console.log('isWallet wallet: ', wallet);
    if (wallet && !wallet?.address) return false;

    const xmtp = get(Xmtp);

    // console.log('isWallet xmtp: ', xmtp);
    // console.log('isWallet xmtp.address: ', xmtp?.address);
    if (!xmtp?.address) {
        WalletVerified.set(false);
        return false;
    }
    if (xmtp?.address.toLowerCase() === wallet?.address.toLowerCase()) {
        WalletVerified.set(true);
        return true;
    }

    WalletVerified.set(false);
    return false;
};

export const getMessages = async (conversation: Conversation, full: boolean = false): Promise<DecodedMessage[]> => {
    let opts = !full
        ? {
              // Only show messages from last 48 hours
              // startTime: new Date(new Date().setDate(new Date().getDate() - 2)),
              // endTime: new Date(),
              direction: SortDirection.SORT_DIRECTION_ASCENDING
          }
        : {
              direction: SortDirection.SORT_DIRECTION_DESCENDING
          };

    const messagesInConversation = await conversation.messages(opts);
    return messagesInConversation;
};

export const arbiChats = async (wallet: string, order: IP2PTradeLiq): Promise<{ maker: Conversation; taker: Conversation } | undefined> => {
    const tradeId = order.tradeId;
    if (!order.taker || !order.maker) return undefined;
    const chats = get(ChatStore);
    const takerArbId = (order.taker.slice(2, 4) + order.taker.slice(19, 21) + order.taker.slice(39, 40)).toUpperCase();
    const makerArbId = order.maker.slice(2, 4) + order.maker.slice(19, 21) + order.maker.slice(39, 40).toUpperCase();
    const arbitratorId = (SKIPSHIFT_ARBITRATOR.slice(2, 4) + SKIPSHIFT_ARBITRATOR.slice(19, 21) + SKIPSHIFT_ARBITRATOR.slice(39, 40)).toUpperCase();

    const arbId0 =
        wallet.toLowerCase() === SKIPSHIFT_ARBITRATOR.toLowerCase()
            ? null
            : wallet.toLowerCase() === order.taker.toLowerCase()
            ? `${takerArbId}_${makerArbId}`
            : `${makerArbId}_${takerArbId}`;
    const arbId1 =
        wallet.toLowerCase() === SKIPSHIFT_ARBITRATOR.toLowerCase()
            ? null
            : wallet.toLowerCase() === order.taker.toLowerCase()
            ? `${makerArbId}_${takerArbId}`
            : `${takerArbId}_${makerArbId}`;

    const w3 = get(web);
    const chainId = get(ChainId) || w3?.eth?.getChainId() || 0;

    let takerChat = await chats.newConversation(SKIPSHIFT_ARBITRATOR, {
        conversationId: `skipShift.tx_${tradeId}_${arbId0}_arbitration_${chainId}`,
        metadata: {
            title: `SkipShift TX#${tradeId} - Arbitration #: ${tradeId}_${arbId0}_${chainId}`
        }
    });

    let makerChat = await chats.newConversation(SKIPSHIFT_ARBITRATOR, {
        conversationId: `skipShift.tx_${tradeId}_${arbId1}_arbitration_${chainId}`,
        metadata: {
            title: `SkipShift TX#${tradeId} - Arbitration #: ${tradeId}_${arbId1}_${chainId}`
        }
    });

    let arbiChat = await chats.newConversation(wallet, {
        conversationId: `skipShift.tx_${tradeId}_${arbId0}_arbitration_${chainId}`,
        metadata: {
            title: `SkipShift TX#${tradeId} - Arbitration #: ${tradeId}_${arbId0}_${chainId}`
        }
    });

    return { maker: makerChat, taker: takerChat };
};

export const sendArbitrationMessage = async (order: IP2PTradeLiq, message: string): Promise<void> => {
    const wallet = get(Wallet);
    const chats = await arbiChats(wallet.address, order);
    if (!chats) return undefined;

    await chats.maker.send(message);
    await chats.taker.send(message);
};

export const newChat = async (recipient: string, tradeId: string | number, debugChat: boolean = false): Promise<Conversation> => {
    console.log('newChat tradeId: ', tradeId);
    const chats = get(ChatStore);
    console.log('newChat chats: ', chats);
    let chat;
    // let chat2;

    const w3 = get(web);
    const chainId = get(ChainId) || w3?.eth?.getChainId() || 0;
    console.log('newChat chainId: ', chainId);

    // debugChat = true;

    if (debugChat) {
        chat = await chats.newConversation(recipient, {
            conversationId: `skipShift.tx_${tradeId}_PayLoad_${skipId()}_${chainId}`,
            metadata: {
                title: `skipShift.tx_${tradeId}_PayLoad_${skipId()}_${chainId}`
            }
        });

        // chat2 = await chats.newConversation(recipient, {
        //   conversationId: `skipShift.tx_${tradeId}_PayLoad_${skipId()}`,
        //   metadata: {
        //     title: `skipShift.tx_${tradeId}_PayLoad_${skipId()}`,
        //   },
        // });
    } else {
        chat = await chats.newConversation(recipient, {
            conversationId: `skipShift.tx_${tradeId}_${chainId}`,
            metadata: {
                title: `SkipShift TX#${tradeId}_${chainId}`
            }
        });

        // chat2 = await chats.newConversation(recipient, {
        //   conversationId: `skipShift.tx_${tradeId}`,
        //   metadata: {
        //     title: `SkipShift TX#${tradeId}`,
        //   },
        // });
    }

    // check messages
    const messages = await chat.messages({ direction: SortDirection.SORT_DIRECTION_DESCENDING });
    // const messages2 = await chat2.messages({ direction: SortDirection.SORT_DIRECTION_DESCENDING });

    console.log('newChat messages: ', messages);
    // console.log('newChat messages2: ', messages2);

    // if(messages.length > 0) return chat;
    // else if(messages2.length > 0) return chat2;

    return chat;
};

export const getKeys = async (wallet: Signer): Promise<Uint8Array> => {
    const keys = await Client.getKeys(wallet, { env: 'production' });

    CryptKey.set(keys);
    return keys;
};

export const buildPayload = async (
    tradeId: string | number,
    venue: IP2PVenue,
    paymentConfId: string = 'pending',
    kypInfo: string = 'N/A'
): Promise<string | undefined> => {
    const tid = typeof tradeId === 'string' ? parseInt(tradeId) : tradeId;
    const loadz = await getLoadz([tid]);

    console.log('loadz: ', loadz);

    if (typeof loadz === 'boolean') return undefined;

    const ld = loadz[tradeId];
    const chainId = ld?.chainId ?? get(ChainId);

    if(!chainId) throw new Error('ChainId not found');

    const payload: IPayLoad = {
        skipShift_tx_id: tradeId,
        venue: venue.platform,
        maker_venue_id: venue.makerId || ld?.maker_venue_id || 'pending',
        taker_venue_id: venue.takerId || ld?.taker_venue_id || 'pending',
        payment_conf_id: paymentConfId || ld?.payment_conf_id || 'pending',
        chain_id: chainId,
        kyp_info: kypInfo
    };

    console.log('payload: ', payload);

    return JSON.stringify(payload);
};


export const submitEncryptedPayload = async (tradeId: string | number, recipient: string, payload: string): Promise<boolean> => {
    const w3 = get(web);
    const chainId = get(ChainId) || w3?.eth?.getChainId() || 0;
    const chats = get(ChatStore);

    console.log('payload', payload);

    const chat = await chats.newConversation(recipient, {
        conversationId: `skipShift.tx_${tradeId}_PayLoad_${skipId()}_${chainId}`,
        metadata: {
            title: `skipShift.tx_${tradeId}_PayLoad_${skipId()}_${chainId}`
        }
    });

    const rct = await chat.send(payload);

    return rct.id ? true : false;
};

export const getLoadz = async (tradeIds: number[]): Promise<{ [key: string]: IEncryptedLoad } | boolean> => {
    const wallet = get(Wallet);
    const chainId = get(ChainId);

    let bigLoad: { [key: string]: IEncryptedLoad } = {};
    let allLoadz: IEncryptedLoad[] = [];
    let parsed: any[] = [];

    if (!(await isWallet())) return false;
    console.log('getLoadz isWallet passed');

    const xmtp = get(Xmtp);
    const regex = /skipShift\.tx_(\d+_PayLoad_\w+_\d+)/;
    // const arrayOfDigits = [229, 230, 231, 232];
    // const regex = new RegExp(`skipShift\\.tx_(${tradeIds.join('|')})_PayLoad_\\w+_\\d+`);

    // const pattern = `skipShift\\.tx_(${tradeIds.join('|')})_PayLoad_\\w+_\\d+`;
    // console.log('getLoadz pattern: ', pattern);

    // // Create the regex object
    // const regex = new RegExp(pattern);
    // console.log('getLoadz regex: ', regex);
    let conversations: Conversation[];

    try {
        conversations = await xmtp.conversations.list();
    } catch (e) {
        console.log('getLoadz xmtp.conversations.list() e: ', e);
        return false;
    }
    conversations.reverse();

    if (!conversations || conversations.length === 0) return {};

    // console.log('getLoadz conversations: ', conversations);
    conversations = conversations.filter((c) => c.context?.conversationId.match(regex));
    // conversations = conversations.filter((c) => c.context?.conversationId && regex.test(c.context?.conversationId));
    // console.log('getLoadz filtered conversations: ', conversations);

    const processConversation = async (convo: Conversation) => {
        if (!convo?.context?.conversationId) return;

        const srch = regex.exec(convo.context.conversationId);
        if (!srch) return;
        const res = srch[1] || null;
        if (!res) return;

        const split = res.split('_');
        const id = split[0];
        const cid = split[split.length - 1];

        if (!id || !cid || cid != chainId?.toString()) return;

        if (parsed.includes(id)) return;
        else parsed.push(id);

        let loadMsgs;
        try {
            const msgs = await convo.messages({
                direction: SortDirection.SORT_DIRECTION_DESCENDING
            });
            if (!msgs.length) return;
            loadMsgs = msgs.find((m) => m.content.includes('maker_venue_id') && m.content.includes('taker_venue_id'));
        } catch (e) {
            console.log('getLoadz e: ', e);
            return;
        }

        if (!tradeIds.includes(+id) || !loadMsgs) return;

        try {
            // console.log('getLoadz ', id.toString(), ' loadMsgs?.content: ', loadMsgs?.content);
            allLoadz.push(JSON.parse(loadMsgs?.content));
            bigLoad[id.toString()] = JSON.parse(loadMsgs?.content);
            bigLoad[id.toString()].sender = loadMsgs.senderAddress;
        } catch (e) {
            console.log('getLoadz e: ', e);
            return;
        }

        let fragmentData = get(XMTPFragmentData)
        XMTPLoadingProgress.set((fragmentData.loaded / fragmentData.total) * 100);
        XMTPFragmentData.set({ loaded: fragmentData.loaded + 1, total: fragmentData.total });
        console.log('new loading progress', get(XMTPLoadingProgress))
    };

    const promises = [];
    for (let i = 0; i < conversations.length; i++) {
        promises.push(processConversation(conversations[i]));
    }

    await Promise.all(promises);

    console.log('getLoadz parsed: ', parsed);
    console.log('getLoadz bigLoad: ', bigLoad);
    console.log('getLoadz allLoadz: ', allLoadz);
    return bigLoad;
};

export const getMessageCounts = async (orders: IP2PTrade[]) => {
    let currentMessageCounts: IMessageTracker = {};

    try {
        let temp = localStorage.getItem(MESSAGE_TRACKER);
        if (temp && Object.values(temp).length > 0) currentMessageCounts = JSON.parse(temp);
    } catch (e) {
        console.log('getMessageCounts e: ', e);
    }

    let messageTracker: IMessageTracker = {};

    const xmtp = get(Xmtp);
    const chainId = get(ChainId);

    if (!xmtp) return 0;
    const conversations = await xmtp.conversations.list();
    if (conversations.length < 1) return 0;

    for (let i = 0; i < orders.length; i++) {
        let order = orders[i];
        let tid = order.tradeId?.toString();
        let cid = order.asset.chainId || chainId;
        if (!tid) continue;

        const convoId = `skipShift.tx_${tid}_${cid}`;
        const matchedConvos = conversations.filter((convo) => convo.context?.conversationId === convoId);
        if (matchedConvos && matchedConvos?.length < 1) continue;

        let convo = matchedConvos[matchedConvos.length - 1];
        const messages = await convo.messages({
            direction: SortDirection.SORT_DIRECTION_DESCENDING
        });

        const currentCount = currentMessageCounts[convoId]?.count || 0;
        const newCount = messages?.length || currentCount;
        const newMessages = currentMessageCounts[convoId]?.newMessages || 0;

        // need to change this so that only setNewMessageCountToZero can update the newMessage count
        messageTracker[convoId] = {
            count: newCount,
            lastCount: currentCount,
            newMessages: newMessages === 0 ? newCount - currentCount : newMessages
        };

        order.unreadMessages = newCount - currentCount;

        messageTrackerStore.update(convoId, messageTracker[convoId]);
    }

    console.log('getMessageCounts messageTracker: ', messageTracker);

    return messageTracker;
};

export const setNewMessageCountToZero = async (orders: IP2PTrade[]) => {
    // create array of conversation ids
    const convoIds = orders.map((o) => `skipShift.tx_${o.tradeId}_${o.asset.chainId}`);
    console.log('setNewMessageCountToZero convoIds: ', convoIds);

    let trackerStore = get(messageTrackerStore);
    console.log('setNewMessageCountToZero trackerStore: ', trackerStore);

    // set new message count to zero
    convoIds.forEach((id) => {
        console.log('setNewMessageCountToZero id: ', id);
        if (!trackerStore[id]) return;
        messageTrackerStore.update(id, {
            count: trackerStore[id].count,
            lastCount: trackerStore[id].count,
            newMessages: 0
        });
    });

    console.log('setNewMessageCountToZero messageTrackerStore: ', get(messageTrackerStore));
};
