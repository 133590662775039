<a
  href="https://rbxdefi.com/integrate"
  target="_blank"
  class="bubble-border border border-sky-500 text-sky-500 hover:text-white hover:bg-sky-500"
  >
  <span class="mr-2">Telegram</span>
  <i class="fa-brands fa-telegram text-sky-500"></i>
</a>

<style lang="scss">
  a {
    @apply py-4 flex items-center justify-center transition-colors duration-300 cursor-pointer md:mr-8;
    width: 100%;
  }
  
  @media (min-width: 768px) {
    a {
      width: 200px;
    }
  }
</style>